<template>
  <div>
    <header>
      <img
        class="cadastro-logo-header tablet-hidden mobile-hidden"
        src="../assets/images/doit-logo.png"
        alt
      />
      <div class="container container500">
        <img
          @click="goBack()"
          class="back pointer"
          src="../assets/images/arrow-back.png"
        />
        <p class="text-desc text-bold text-center text-blue white-bold-desktop">
          {{ t("Cadastro") }}
        </p>
        <div class="signin-step-holder">
          {{ `${t("Passo")} ${signUpStep} / 4` }}
        </div>
      </div>
    </header>

    <div v-show="creatingSubscription" class="bg-cover flex-center">
      <div class="desktop12 tablet12 mobile12 flex-center flex-column">
        <div class="loading-spinner"></div>
        <p class="text-desc mt-1 text-white text-center">
          {{ t("Processando pagamento") }}. <br />{{ t("Por favor, aguarde") }}.
        </p>
      </div>
    </div>

    <div v-show="signUpStep == 1" class="page-anim2">
      <div class="container container500">
        <h1 class="title text-blue mt-2">{{ t("Dados pessoais") }}</h1>
        <div
          class="input-holder labeled login first desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">
            {{ t("Nome completo") }} *
          </p>
          <img src="../assets/images/icon-user.png" />
          <input
            v-model="user.name"
            type="text"
            :placeholder="t('Ex:') + ' Maria da Silva'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">
            {{ t("Nome profissional") }} *
          </p>
          <img src="../assets/images/icon-user.png" />
          <input
            v-model="user.professionalName"
            type="text"
            :placeholder="t('Ex:') + ' Dra Maria'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">E-mail *</p>
          <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.email"
            type="email"
            :placeholder="t('Ex:') + ' ' + t('nome@dominio.com')"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("CPF") }} *</p>
          <img class="icon-cpf" src="../assets/images/icon-cpf.png" />
          <the-mask
            v-model="user.cpf"
            :mask="['###.###.###-##', '##.###.###/####-##']"
            :masked="false"
            :placeholder="t('Ex:') + ' 123.456.789-10'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">
            {{ t("Data de nascimento") }} *
          </p>
          <img src="../assets/images/icon-birthday.png" />
          <the-mask
            v-model="birthday"
            :placeholder="t('DD/MM/AAAA')"
            :mask="['##/##/####']"
            :masked="true"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("Telefone") }} *</p>
          <img src="../assets/images/icon-phone.png" />
          <the-mask
            v-model="user.phone"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="false"
            :placeholder="t('Ex:') + ' (11) 1234-5678'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("CEP") }} *</p>
          <img class="icon-address" src="../assets/images/icon-address.png" />
          <the-mask
            @blur.native="getCep()"
            v-model="user.zipcode"
            :mask="['#####-###']"
            :masked="false"
            :placeholder="t('Ex:') + ' 12345-678'"
          />
        </div>

        <transition enter-active-class="animated fadeIn">
          <div v-show="user.zipcode.length > 7">
            <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
              <p class="text-desc text-brown text-bold">{{ t("UF") }} *</p>
              <img
                class="icon-address"
                src="../assets/images/icon-address.png"
              />
              <input
                v-model="user.state"
                type="text"
                :placeholder="t('Ex:') + ' SP'"
              />
            </div>
            <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
              <p class="text-desc text-brown text-bold">{{ t("Cidade") }} *</p>
              <img
                class="icon-address"
                src="../assets/images/icon-address.png"
              />
              <input v-model="user.city" type="text" />
            </div>
            <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
              <p class="text-desc text-brown text-bold">{{ t("Bairro") }} *</p>
              <img
                class="icon-address"
                src="../assets/images/icon-address.png"
              />
              <input v-model="user.neighborhood" type="text" />
            </div>
            <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
              <p class="text-desc text-brown text-bold">
                {{ t("Endereço") }} *
              </p>
              <img
                class="icon-address"
                src="../assets/images/icon-address.png"
              />
              <input
                v-model="user.address"
                type="text"
                :placeholder="
                  t('Ex:') + ' ' + t('Rua') + ' Washington, 123, SP'
                "
              />
            </div>
            <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
              <p class="text-desc text-brown text-bold">{{ t("Número") }} *</p>
              <img
                class="icon-address"
                src="../assets/images/icon-address.png"
              />
              <input v-model="user.number" type="text" placeholder />
            </div>
          </div>
        </transition>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("Gênero") }} *</p>
          <img src="../assets/images/icon-user.png" />
          <select v-model="user.genre">
            <option value>{{ t("Selecione uma opção") }}</option>
            <option value="female">{{ t("Feminino") }}</option>
            <option value="male">{{ t("Masculino") }}</option>
          </select>
        </div>

        <transition enter-active-class="animated fadeIn">
          <div
            v-show="user.genre"
            class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
          >
            <p class="text-desc text-brown text-bold">
              {{ t("Nível de Carreira") }} *
            </p>
            <img src="../assets/images/icon-user.png" />
            <select v-model="user.career">
              <option value>{{ t("Selecione uma opção") }}</option>
              <option
                v-for="(career, index) in careers"
                :key="index"
                :value="career"
              >
                {{ career[user.genre] }}
              </option>
            </select>
          </div>
        </transition>

        <div
          v-show="!renewal"
          class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">{{ t("Senha") }} *</p>
          <img class="icon-address" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.password"
            type="password"
            placeholder="*******"
          />
        </div>

        <div
          v-show="!renewal"
          class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">
            {{ t("Confirmar Senha") }} *
          </p>
          <img class="icon-address" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.password2"
            type="password"
            placeholder="*******"
          />
        </div>

        <p class="text-desc terms mt-2">
          {{ t("Ao continuar você confirma que") }} <br />{{
            t("leu e está de acordo com os nossos")
          }}
          <router-link to="/termos" target="_blank">
            {{t("termos de uso")}}
          </router-link>.
        </p>

        <transition enter-active-class="animated fadeIn">
          <p
            v-show="warningMessage != ''"
            class="text-desc text-center text-blue mt-2"
          >
            {{ warningMessage }}
          </p>
        </transition>

        <button @click="nextStep()" class="default bg-brown my-2">
          {{ t("Avançar") }}
        </button>
      </div>
    </div>

    <transition enter-active-class="page-anim2">
      <div class="pb-2-desktop" v-show="signUpStep == 2">
        <div class="container flex-between-desktop">
          <h1 class="title text-blue mt-2 desktop12">{{ t("Planos") }}</h1>
          <div
            v-for="plan in plans"
            :key="plan.id"
            class="plan-card mt-1 pointer desktop12 tablet12 mobile12"
            :class="{
              'shadow-default': selectedPlan.id == plan.id,
            }"
            @click="selectedPlan = plan"
          >
            <div class="plan-header flex-between">
              <p class="text-desc text-white text-bold">{{ plan.name }}</p>
              <p class="text-desc text-brown-light text-bold">
                {{ plan.value | currency }} / {{ t("mês") }}
              </p>
            </div>
            <p class="text-desc text-light description text-brown">
              {{ plan.description }}
            </p>
            <p class="text-desc text-light text-blue text-bold type">
              {{ t("Assinatura mensal recorrente") }}
            </p>
          </div>
          <div class="container small coupon desktop4 mt-3-desktop">
            <div class="input-holder coupon my-2">
              <img src="../assets/images/icon-coupon.png" />
              <input
                v-model="couponCode"
                type="text"
                :placeholder="t('Cupom de desconto')"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc text-blue text-center mt-2"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button @click="pickPlan()" class="default bg-brown mt-2 mb-2">
              <span v-show="!loading">
                {{
                  selectedPlan.id && couponCode && !validCoupon
                    ? t("Validar Cupom")
                    : t("Avançar")
                }}
              </span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </div>
    </transition>

    <transition enter-active-class="page-anim2">
      <div class="pb-2-desktop" v-show="signUpStep == 3">
        <div class="container flex-between-desktop">
          <h1 class="title text-blue mt-2 desktop12">
            {{ t("Escolha a forma de pagamento") }}
          </h1>
          <div
            class="plan-card mt-1 pointer desktop12 tablet12 mobile12"
            @click="
              signUpStep = 4;
              paymentMethod = 0;
            "
          >
            <p class="text-desc text-light description text-brown text-center">
              <img src="../assets/images/credit-cards-payment.png" width="80" />
            </p>
            <p
              class="
                text-light text-blue text-bold
                type
                text-center
                payment-text
              "
            >
              {{ t("Cartão de Crédito") }}
            </p>
          </div>
          <div
            class="plan-card mt-1 pointer desktop12 tablet12 mobile12"
            @click="
              signUpStep = 4;
              paymentMethod = 1;
            "
          >
            <p class="text-desc text-light description text-brown text-center">
              <img src="../assets/images/barcode-payment.png" width="80" />
            </p>
            <p
              class="
                text-light text-blue text-bold
                type
                text-center
                payment-text
              "
            >
              {{ t("Boleto") }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition enter-active-class="page-anim2">
      <div v-show="signUpStep == 4">
        <div class="container container500" v-show="paymentMethod == 1">
          <h1 class="title text-blue mt-2">{{ t("Resumo do Pedido") }}</h1>
          <div class="mt-2">
            <p class="order-summary-title">{{ t("Nome") }}:</p>
            <p class="order-summary-value">{{ user.name }}</p>

            <p class="order-summary-title">E-mail:</p>
            <p class="order-summary-value">{{ user.email }}</p>

            <p class="order-summary-title">{{ t("Plano") }}:</p>
            <p class="order-summary-value">
              {{ selectedPlan.name }} ({{ selectedPlan.value | currency }} /
              {{ t("mês") }})
            </p>

            <p class="order-summary-title">{{ t("Descrição do plano") }}:</p>
            <p class="order-summary-value">{{ selectedPlan.description }}</p>

            <p class="order-summary-title">{{ t("Método de Pagamento") }}:</p>
            <p class="order-summary-value">{{ t("Boleto") }}</p>
          </div>

          <button @click="signUp()" class="default bg-brown mt-2 mb-2">
            <span v-show="!loading">{{ t("Cadastrar") }}</span>
            <div v-show="loading" class="loading-spinner"></div>
          </button>
        </div>

        <div class="container container500" v-show="paymentMethod == 0">
          <h1 class="title text-blue mt-2">{{ t("Dados do cartão") }}</h1>

          <div class="cb-holder mt-2">
            <input
              @change="setUserDataForCard()"
              v-model="useUserData"
              type="checkbox"
              id="useMyInfo"
            />
            <label for="useMyInfo" class="text-desc text-brown">{{
              t(
                "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)"
              )
            }}</label>
          </div>
          <div class="container container500 pb-55-mobile">
            <div v-show="!useUserData">
              <div
                class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
              >
                <p class="text-desc text-brown text-bold">{{ t("CPF") }} *</p>
                <img class="icon-cpf" src="../assets/images/icon-cpf.png" />
                <the-mask
                  v-model="userCard.cpf"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  :masked="false"
                  :placeholder="t('Ex:') + ' 123.456.789-10'"
                />
              </div>
              <div
                class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
              >
                <p class="text-desc text-brown text-bold">E-mail *</p>
                <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
                <input
                  v-model="userCard.email"
                  type="email"
                  :placeholder="t('Ex:') + ' ' + t('nome@dominio.com')"
                />
              </div>
              <div
                class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
              >
                <p class="text-desc text-brown text-bold">
                  {{ t("Data de nascimento") }} *
                </p>
                <img src="../assets/images/icon-birthday.png" />
                <the-mask
                  v-model="userCard.auxBirthday"
                  :placeholder="t('DD/MM/AAAA')"
                  :mask="['##/##/####']"
                  :masked="true"
                />
              </div>
              <div
                class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
              >
                <p class="text-desc text-brown text-bold">
                  {{ t("Telefone") }} *
                </p>
                <img src="../assets/images/icon-phone.png" />
                <the-mask
                  v-model="userCard.phone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="false"
                  :placeholder="t('Ex:') + '(11) 1234-5678'"
                />
              </div>

              <div
                class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
              >
                <p class="text-desc text-brown text-bold">{{ t("CEP") }} *</p>
                <img
                  class="icon-address"
                  src="../assets/images/icon-address.png"
                />
                <the-mask
                  @blur.native="getCepCard()"
                  v-model="userCard.zipcode"
                  :mask="['#####-###']"
                  :masked="false"
                  :placeholder="t('Ex:') + '12345-678'"
                />
              </div>

              <transition enter-active-class="animated fadeIn">
                <div v-show="user.zipcode.length > 7">
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("UF") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <input
                      v-model="userCard.state"
                      type="text"
                      :placeholder="t('Ex:') + ' SP'"
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Cidade") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <input v-model="userCard.city" type="text" />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Bairro") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <input v-model="userCard.neighborhood" type="text" />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Endereço") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <input
                      v-model="userCard.address"
                      type="text"
                      :placeholder="
                        t('Ex:') + ' ' + t('Rua') + ' Washington, 123, SP'
                      "
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Número da residência") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <input v-model="userCard.number" type="text" placeholder />
                  </div>
                </div>
              </transition>
            </div>

            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Nome impresso no cartão") }} *
              </p>
              <img src="../assets/images/icon-card.png" />
              <input v-model="creditCard.name" type="text" />
            </div>
            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Número do cartão") }} *
              </p>
              <img src="../assets/images/icon-card.png" />
              <the-mask
                @input="checkCardFlag"
                v-model="creditCard.number"
                :mask="['#### #### #### ####']"
                :masked="false"
              />
            </div>
            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Bandeira do cartão") }} *
              </p>
              <img src="../assets/images/icon-card.png" />
              <select
                @change="setCreditCardFlagImage"
                v-model="creditCard.flag"
              >
                <option value="mastercard">Mastercard</option>
                <option value="visa">Visa</option>
                <option value="amex">American Express</option>
                <option value="elo">Elo</option>
                <option value="hipercard">Hipercard</option>
                <!-- <option value="diners">Diners</option> -->
              </select>
            </div>
            <div class="flex-between">
              <div
                class="
                  input-holder
                  half
                  labeled
                  login
                  first
                  desktop12
                  tablet12
                  mobile5
                "
              >
                <p class="text-desc text-brown text-bold">
                  {{ t("vencimento") }} *
                </p>
                <img src="../assets/images/icon-calendar.png" />
                <the-mask
                  v-model="creditCard.month"
                  :placeholder="t('Mês')"
                  :mask="['##']"
                  :masked="false"
                />
              </div>
              <div
                class="
                  input-holder
                  half
                  labeled
                  login
                  first
                  desktop12
                  tablet12
                  mobile5
                "
              >
                <p class="text-desc text-brown text-bold"></p>
                <img src="../assets/images/icon-calendar.png" />
                <the-mask
                  v-model="creditCard.year"
                  :placeholder="t('Ano') + ': ' + t('AAAA')"
                  :mask="['####']"
                  :masked="false"
                />
              </div>
            </div>
            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Código de segurança") }} *
              </p>
              <img src="../assets/images/icon-lock.png" />
              <input
                v-model="creditCard.securityCode"
                type="number"
                :placeholder="t('Ex:') + ' 123'"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <div
                v-show="creditCard.name && creditCard.number"
                class="creditcard my-2"
              >
                <img class="chip" src="../assets/images/icon-cpu.png" alt />
                <p class="subtitle number text-white">
                  {{ creditCard.number }}
                </p>
                <p
                  style="text-transform: uppercase"
                  class="subtitle name text-white"
                >
                  {{ creditCard.name }}
                </p>
                <div class="dates flex-start">
                  <p class="text-desc text-white">
                    {{ t("Validade") }}:
                    <b>{{
                      ` ${this.creditCard.month}/${this.creditCard.year}`
                    }}</b>
                  </p>
                </div>
                <img
                  v-show="creditCardFlagImage"
                  :src="creditCardFlagImage"
                  class="flag"
                />
                <p class="text-desc security-code text-white">
                  {{ creditCard.securityCode }}
                </p>
              </div>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc text-blue text-center mt-2"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button @click="signUp" class="default bg-brown mt-2 mb-2">
              <span v-show="!loading">{{ t("Cadastrar") }}</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import validators from "../util/validators";
import creditCardType from "credit-card-type";

export default {
  name: "Cadastro",
  data() {
    return {
      creatingSubscription: false,
      warningMessage: "",
      loading: false,
      genre: "female",
      careers: [],
      birthday: "",
      renewal: false,
      user: {
        name: "",
        email: "",
        image: "",
        cpf: "",
        phone: "",
        address: "",
        number: "",
        neighborhood: "",
        zipcode: "",
        city: "",
        state: "",
        professionalName: "",
        consultantCode: "",
        principalName: "",
        genre: "",
        career: "",
        password: "",
        password2: "",
        birthday: "",
        country: "Brasil",
      },
      userCard: {
        name: "",
        email: "",
        phone: "",
        cpf: "",
        birthday: "",
        auxBirthday: "",
        address: "",
        number: "",
        neighborhood: "",
        zipcode: "",
        city: "",
        state: "",
      },
      useUserData: true,
      creditCard: {
        number: "",
        name: "",
        month: "",
        year: "",
        securityCode: "",
        flag: "",
      },
      creditCardFlagImage: "",
      signUpStep: 1,
      paymentMethod: "",
      plans: [],
      selectedPlan: {
        id: null,
        name: "",
        value: 0,
      },
      couponCode: "",
      consulta: {},
      validCoupon: false,
      language: "",
    };
  },
  created() {
    this.language = localStorage.language || "br";
    this.$translate.setLang(this.language);
    console.log(this.language);
    http
      .get("plan?paymentMethod=Gerencianet")
      .then((response) => {
        this.plans = response.data;
      })
      .catch((err) => {
        console.error(err);
      });
    http
      .get("career?take=-1")
      .then((response) => {
        this.careers = response.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.$route.query.renew === "y") {
      http
        .get("user/details", authentication.getHeaders())
        .then((response) => {
          this.renewal = true;
          this.user = response.data;
          this.birthday = this.$moment(this.user.birthday).format("DD/MM/YYYY");
          this.nextStep();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) this.$router.push("login");
        });
    }
  },
  methods: {
    setUserDataForCard() {
      if (this.useUserData) {
        this.userCard = { ...this.userCard, ...this.user };
        this.userCard.auxBirthday = this.$moment(this.user.birthday).format(
          "DD/MM/YYYY"
        );
        console.log(this.userCard);
      } else {
        this.userCard = {
          name: "",
          email: "",
          phone: "",
          cpf: "",
          birthday: "",
          address: "",
          number: "",
          neighborhood: "",
          zipcode: "",
          city: "",
          state: "",
        };
      }
    },
    setCreditCardFlagImage() {
      if (this.creditCard.flag == "mastercard") {
        this.creditCardFlagImage = require("../assets/images/mastercard.png");
      } else if (this.creditCard.flag == "visa") {
        this.creditCardFlagImage = require("../assets/images/visa.png");
      } else if (this.creditCard.flag == "amex") {
        this.creditCardFlagImage = require("../assets/images/amex.jpg");
      } else if (this.creditCard.flag == "diners") {
        this.creditCardFlagImage = require("../assets/images/diners.png");
      } else if (this.creditCard.flag == "elo") {
        this.creditCardFlagImage = require("../assets/images/elo.png");
      } else if (this.creditCard.flag == "hipercard") {
        this.creditCardFlagImage = require("../assets/images/hipercard.png");
      } else if (this.creditCard.flag == "maestro") {
        this.creditCardFlagImage = require("../assets/images/mastercard.png");
      } else {
        this.creditCardFlagImage = "";
      }
    },
    checkCardFlag() {
      let flag = creditCardType(this.creditCard.number)[0]?.type
        ? creditCardType(this.creditCard.number)[0].type
        : false;

      this.creditCard.flag = flag;
      if (flag === "american-express") this.creditCard.flag = "amex";
      else if (flag === "diners-club") this.creditCard.flag = "diners";
      else if (flag === "maestro") this.creditCard.flag = "mastercard";

      this.setCreditCardFlagImage();
    },
    getCep() {
      this.user.zipcode = this.user.zipcode.replace(/[^\d]/g, "");

      if (this.user.zipcode.length != 8) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um cep válido"];
        return;
      } else {
        http
          .get(`https://viacep.com.br/ws/${this.user.zipcode}/json/`)
          .then((response) => {
            this.user.state = response.data.uf;
            this.user.city = response.data.localidade;
            this.user.neighborhood = response.data.bairro;
            this.user.address = response.data.logradouro;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.showFullAddress = true;
          });
      }
    },
    getCepCard() {
      this.userCard.zipcode = this.userCard.zipcode.replace(/[^\d]/g, "");

      if (this.userCard.zipcode.length != 8) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um cep válido"];
        return;
      } else {
        this.warningMessage = "";
        http
          .get(`https://viacep.com.br/ws/${this.userCard.zipcode}/json/`)
          .then((response) => {
            this.userCard.state = response.data.uf;
            this.userCard.city = response.data.localidade;
            this.userCard.neighborhood = response.data.bairro;
            this.userCard.address = response.data.logradouro;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.showFullAddressCard = true;
          });
      }
    },
    nextStep() {
      this.user.name = this.user.name.trim();
      if (!validators.validateFullName(this.user.name)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nome inteiro"];
        return;
      }
      if (this.user.professionalName == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nome profissional"];
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um e-mail válido"];
        return;
      }
      if (
        !validators.validateCPF(this.user.cpf) &&
        !validators.validateCNPJ(this.user.cpf)
      ) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu cpf corretamente"];
        return;
      }
      let dateRegex = /^\d{2,2}\/\d{2,2}\/\d{4,4}$/;
      if (!dateRegex.test(this.birthday)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe sua data de nascimento corretamente"
          ];
        return;
      }
      let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
      if (!phoneRegex.test(this.user.phone)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe um número de telefone ou celular válido"
          ];
        return;
      }
      if (this.user.zipcode == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu cep"];
        return;
      }
      if (this.user.state == "" || this.user.state.length != 2) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um UF válido"];
        return;
      }
      if (this.user.city == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe a cidade"];
        return;
      }
      if (this.user.neighborhood == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o bairro"];
        return;
      }
      if (this.user.address == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu endereço"];
        return;
      }
      if (this.user.number == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu número"];
        return;
      }
      if (this.user.genre == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu gênero"];
        return;
      }
      if (this.user.career == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nível de carreira"];
        return;
      }
      if (this.user.password == "" && !this.renewal) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe sua senha"];
        return;
      }
      if (this.user.password2 == "" && !this.renewal) {
        this.warningMessage =
          this.$translate.locale["Por favor, confirme a sua senha"];
        return;
      }
      if (this.user.password !== this.user.password2 && !this.renewal) {
        this.warningMessage =
          this.$translate.locale[
            "Suas senhas são diferentes. Por favor, digite-as novamente"
          ];
        return;
      }

      let splitDate = this.birthday.split("/");
      this.user.birthday = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

      this.warningMessage = "";
      this.signUpStep = 2;
    },
    pickPlan() {
      if (!this.selectedPlan.id) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, selecione um plano para continuar"
          ];
        return;
      }

      if (this.couponCode && this.couponCode != this.validCoupon) {
        this.loading = true;
        http
          .get(`coupon/validate/${this.couponCode}`)
          .then((response) => {
            this.warningMessage = response.data.message;

            if (response.data.valid) {
              this.validCoupon = this.couponCode;
            } else {
              this.couponCode = "";
            }
          })
          .catch(() => {
            this.warningMessage =
              this.$translate.locale[
                "Algo deu errado. Por favor, tente novamente em alguns instantes."
              ];
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.warningMessage = "";
        this.signUpStep = 3;
        this.setUserDataForCard();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    signUp() {
      if (!validators.validateEmail(this.userCard.email)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o e-mail do dono do cartão"
          ];
        return;
      }
      if (
        !validators.validateCPF(this.userCard.cpf) &&
        !validators.validateCNPJ(this.userCard.cpf)
      ) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o cpf do dono o cartão"];
        return;
      }
      let dateRegex = /^\d{2,2}\/\d{2,2}\/\d{4,4}$/;
      if (!dateRegex.test(this.userCard.auxBirthday)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe a data de nascimento do dono do cartão"
          ];
        return;
      }
      let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
      if (!phoneRegex.test(this.userCard.phone)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o número de telefone ou celular do dono do cartão"
          ];
        return;
      }
      if (this.userCard.zipcode == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o cep do dono do cartão"];
        return;
      }
      if (this.userCard.state == "" || this.userCard.state.length != 2) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o UF do dono do cartão"];
        return;
      }
      if (this.userCard.city == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe a cidade do dono do cartão"
          ];
        return;
      }
      if (this.userCard.neighborhood == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o bairro do dono do cartão"
          ];
        return;
      }
      if (this.userCard.address == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o endereço do dono do cartão"
          ];
        return;
      }
      if (this.userCard.number == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o número do dono do cartão"
          ];
        return;
      }

      if (this.paymentMethod == 0) {
        this.creditCard.name = this.creditCard.name.trim();
        if (this.creditCard.name.length < 3) {
          this.warningMessage =
            this.$translate.locale[
              "Por favor, informe o nome igual está escrito no cartão"
            ];
          return;
        }

        if (!this.creditCard?.flag) {
          this.warningMessage =
            this.$translate.locale[
              "Por favor, informe um número de cartão válido"
            ];
          return;
        }
        if (this.creditCard.month == "") {
          this.warningMessage =
            this.$translate.locale[
              "Por favor, informe o mês de validade do cartão"
            ];
          return;
        }
        if (this.creditCard.year.length !== 4) {
          this.warningMessage =
            this.$translate.locale[
              "Por favor, informe o ano de validade do cartão no formato AAAA"
            ];
          return;
        }
        if (this.creditCard.securityCode == "") {
          this.warningMessage =
            this.$translate.locale[
              "Por favor, informe o código de segurança do cartão"
            ];
          return;
        }
      }

      let splitDate = this.userCard.auxBirthday.split("/");
      this.userCard.birthday = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

      this.warningMessage = "";
      this.loading = true;
      this.validCoupon = this.validCoupon == false ? "" : this.validCoupon;
      if (this.renewal) {
        if (this.paymentMethod == 0) {
          this.createSubscriptionByCreditCard(this.user);
        } else {
          this.createSubscriptionByBillet(this.user);
        }
      } else {
        http
          .post("user", this.user)
          .then((response) => {
            localStorage.user = JSON.stringify(response.data);
            this.$swal
              .fire({
                type: "success",
                title: this.$translate.locale["Cadastro realizado com sucesso"],
              })
              .then((res) => {
                if (res) {
                  if (this.paymentMethod == 0) {
                    this.createSubscriptionByCreditCard(response.data.info);
                  } else {
                    this.createSubscriptionByBillet(response.data.info);
                  }
                }
              });
          })
          .catch((err, msg) => {
            this.warningMessage = err.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createSubscriptionByBillet(user) {
      var vm = this;
      var subscriptionInfo = {
        paymentMethod: "GerencianetBoleto",
        PlanId: vm.selectedPlan.id,
        PaymentToken: "",
        coupon: {
          code: vm.validCoupon,
        },
      };

      var subscriptionForm = {
        subscription: subscriptionInfo,
        user: vm.userCard,
      };
      http
        .post("Subscription", subscriptionForm, authentication.getHeaders())
        .then(() => {
          vm.$swal
            .fire({
              type: "success",
              title: vm.$translate.locale["Recebemos seu pedido"],
              text: `${vm.$translate.locale["Você recebrá o boleto no e-mail"]} ${vm.userCard.email} ${vm.$translate.locale["em até 48 horas"]}`,
            })
            .then((res) => {
              if (res) {
                vm.$router.push("/home?newUser=true");
              }
            });
        })
        .catch((err) => {
          console.log(err);
          vm.$swal
            .fire({
              type: "error",
              title: vm.$translate.locale["Algo deu errado"],
              text: vm.$translate.locale[
                "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente"
              ],
            })
            .then((res) => {
              if (res) {
                localStorage.paymentFailed = true;
                vm.$router.push("/home?newUser=true");
              }
            });
        })
        .finally(() => {
          vm.creatingSubscription = false;
          vm.loading = false;
        });
    },
    createSubscriptionByCreditCard(user) {
      var vm = this;
      var cc = this.creditCard;
      vm.creatingSubscription = true;

      consulta.getPaymentToken(
        {
          brand: cc.flag, // bandeira do cartão
          number: cc.number, // número do cartão
          cvv: cc.securityCode, // código de segurança
          expiration_month: cc.month, // mês de vencimento
          expiration_year: cc.year, // ano de vencimento
        },
        function (error, response) {
          if (error) {
            console.log(error);
            vm.$swal
              .fire({
                type: "error",
                title: vm.$translate.locale["Algo deu errado"],
                text: vm.$translate.locale[
                  "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente."
                ],
              })
              .then((res) => {
                if (res) {
                  localStorage.paymentFailed = true;
                  vm.$router.push("/home?newUser=true");
                }
              });
            http.post("message/error", {
              page: "Cadastro: GetPaymentToken",
              message: error,
            });
            vm.creatingSubscription = false;
            vm.loading = false;
          } else {
            var payment_token = response.data.payment_token;
            var subscriptionInfo = {
              paymentMethod: "Gerencianet",
              PlanId: vm.selectedPlan.id,
              PaymentToken: payment_token,
              coupon: {
                code: vm.validCoupon,
              },
            };

            vm.userCard.name = vm.creditCard.name;

            var subscriptionForm = {
              subscription: subscriptionInfo,
              user: vm.userCard,
            };
            http
              .post(
                "Subscription",
                subscriptionForm,
                authentication.getHeaders()
              )
              .then(() => {
                vm.$swal
                  .fire({
                    type: "success",
                    title: vm.$translate.locale["Recebemos sua transação"],
                    text: vm.$translate.locale[
                      "A confirmação do pagamento pode levar até 48 horas"
                    ],
                  })
                  .then((res) => {
                    if (res) {
                      vm.$router.push("/home?newUser=true");
                    }
                  });
              })
              .catch((err) => {
                console.log(err);
                vm.$swal
                  .fire({
                    type: "error",
                    title: vm.$translate.locale["Algo deu errado"],
                    text: vm.$translate.locale[
                      "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente"
                    ],
                  })
                  .then((res) => {
                    if (res) {
                      localStorage.paymentFailed = true;
                      vm.$router.push("/home?newUser=true");
                    }
                  });
              })
              .finally(() => {
                vm.creatingSubscription = false;
                vm.loading = false;
              });
          }
        }
      );
    },
    goBack() {
      if (
        (this.signUpStep > 1 && !this.renewal) ||
        (this.signUpStep > 2 && this.renewal)
      ) {
        this.signUpStep -= 1;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped>
p.terms {
  position: relative;
  text-align: center;
}

p.terms a {
  font-size: 1em;
  color: #0f2651;
  font-weight: bold;
}

p.text-desc.type {
  margin: 5px 0 0 15px;
}

.input-holder.half {
  width: 48% !important;
}

.payment-text {
  font-size: 16px;
}

.order-summary-title {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
}

.order-summary-value {
  font-size: 14px;
  margin-top: 4px;
}
</style>
